import { Grid, Button, DialogTitle, Dialog, DialogActions, DialogContent } from "@mui/material";
import RegisterNow from "../assets/depositphotos_40248067-stock-illustration-register-now-button-removebg-preview.png";
import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import { useMediaQuery } from "@mui/material";
import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useTheme } from "@mui/material";
import { postData } from "../Services/FetchNodeServices";
import Swal from "sweetalert2";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import registration from '../assets/registration.jpg'
import registationbg from '../assets/pngtree-wedding-western-style-decoration-background-picture-image_2249690.jpg'

export default function Registration() {
    const [name, setName] = useState('');
    const [password, setPassword] = useState('');
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [email, setEmail] = useState('');
    const [number, setNumber] = useState('');
    const [open, setOpen] = useState(false);
    const [isPinkHovered, setIsPinkHovered] = useState(false);
    
    const navigate = useNavigate();
    const location = useLocation();
    const { open: isOpen, from = null, id = null } = location.state || {};
    const theme = useTheme();
    const matchesMd = useMediaQuery(theme.breakpoints.down("md"));

    useEffect(() => {
        if (isOpen) {
            setOpen(true);
        }
    }, [isOpen]);

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = async () => {
if(!name || !email || !number || !password){

    Swal.fire({
        position:'top-end',
        icon:"warning",
        title:"please fill in all the fields",
        showConfirmButton: false,
        timer: 1500,
        toast: true


    });
    return;
}



        try {
            const body = { phone: number, name, email, password };
            console.log("Request body:", body);
            
            const result = await postData("api-user", body);
            console.log("API response:", result);

            if (result && result.status) {
                localStorage.setItem("userDetails", JSON.stringify(body));
                
                Swal.fire({
                    position: "top-end",
                    icon: "success",
                    title: "You are registered now....",
                    showConfirmButton: false,
                    timer: 1500,
                    toast: true
                });

                if (from) {
                    navigate(from, { state: { id, from } });
                } else {
                    navigate("/profile");
                }
            } else {
                Swal.fire({
                    position: "top-end",
                    icon: "error",
                    title: result.message,
                    showConfirmButton: false,
                    timer: 1500,
                    toast: true
                });
            }
        } catch (error) {
            console.error("An error occurred during registration:", error);
            Swal.fire({
                position: "top-end",
                icon: "error",
                title: "An error occurred. Please try again.",
                showConfirmButton: false,
                timer: 1500,
                toast: true
            });
        }
    };

    return (
        // <Dialog maxWidth={"lg"} open={open} onClose={handleClose}>
        <div style={{
            margin: "auto",
            padding: "20px",
            backgroundImage:`url(${registationbg})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundColor: "rgba(0, 0, 0, 0.7)", // Semi-transparent black layer
            backgroundBlendMode: "overlay", // Blends the color and image
            minHeight: "100vh", // Ensures the background covers the entire viewport
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        }}>
        <div style={{ maxWidth: "1200px", margin: "auto", padding: "20px" }}>
            <DialogTitle>
                <Grid container justifyContent="center" >
                    <img src={RegisterNow} style={{ width: '25%', height: '20%' }} alt="Register Now"/>
                </Grid>
            </DialogTitle>
            <DialogContent>
                {[{
                    icon: <PersonIcon style={{ padding: 10 }} />,
                    value: name,
                    onChange: (e) => setName(e.target.value),
                    placeholder: "Enter Name",
                    type: "text",
                }, {
                    icon: <LocalPhoneIcon style={{ padding: 10 }} />,
                    value: number,
                    onChange: (e) => setNumber(e.target.value),
                    placeholder: "Enter Number",
                    type: "text",
                }, {
                    icon: <EmailIcon style={{ padding: 10 }} />,
                    value: email,
                    onChange: (e) => setEmail(e.target.value),
                    placeholder: "Enter E-mail Id",
                    type: "email",
                }, 
                {
                    icon: <VpnKeyIcon style={{ padding: 10 }} />,
                    value: password,
                    onChange: (e) => setPassword(e.target.value),
                    placeholder: "Enter Password",
                    isPassword: true
                    
                }].map((field, index) => (
                    <Grid item xs={12} key={index} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: 10 }}>
                        <div style={{ display: 'flex', alignItems: 'center', background: '#fff', width: matchesMd ? '85%' : '100%', height: '28%', border: 'solid 1px' }}>
                            {field.icon}
                            <input 
                                value={field.value} 
                                onChange={field.onChange} 
                                style={{ height: 50, width: '100%', background: 'transparent', border: 'none', outline: 'none', color: '#797979', fontSize: 12, marginLeft: matchesMd ? '' : 10 }} 
                                type={
                                    field.isPassword
                                        ? isPasswordVisible
                                            ? "text"
                                            : "password"
                                        : field.type
                                } // Use the conditional logic only for password fields
                                placeholder={field.placeholder} 
                            />

{field.isPassword && (
                    <div
                        onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                        style={{ cursor: "pointer", padding: 10 }}
                    >
                        {isPasswordVisible ? (
                            <VisibilityOff />
                        ) : (
                            <Visibility />
                        )}
                    </div>
                )}





                            
                        </div>
                    </Grid>
                ))}
                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 30 }}>
                    <div 
                        onClick={handleSubmit} 
                        style={{
                            width: matchesMd ? 80 : 90, 
                            height: 35, 
                            background: isPinkHovered ? 'linear-gradient(to bottom, #e72e77, #bb708d)' : 'linear-gradient(to bottom, #e72e77, #bb708d)',
                            display: 'flex', 
                            color: isPinkHovered ? 'white' : 'white', 
                            fontSize: 12, 
                            alignItems: 'center', 
                            justifyContent: 'center', 
                            fontWeight: 'bold', 
                            borderRadius: 30, 
                            border: 'solid 1px', 
                            cursor: 'pointer'
                        }} 
                        onMouseEnter={() => setIsPinkHovered(true)} 
                        onMouseLeave={() => setIsPinkHovered(false)}
                    >
                        Submit
                    </div>
                </Grid>
            </DialogContent>
           
        {/* </Dialog> */}
        </div>

        </div>
    );
}
