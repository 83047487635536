import { Grid,Button,Typography,Link, DialogTitle} from "@mui/material"
import { getData, postData } from "../../Services/FetchNodeServices";
import { useState,useEffect,useContext } from "react";
import usericon from "../../assets/usericon.png";
import google from "../../assets/google.webp";
import UpperHeader from "./UpperHeader";
import Header from "./Header";
import { useMediaQuery } from "@mui/material";
import {useTheme} from "@mui/material";
import{auth,provider} from "../../../src/Component/userinterface/config"
import {signInWithPopup} from "firebase/auth"
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../App"
import passworde from "../../assets/passworde.png"
import { createTheme } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import RegisterNow from "../../assets/RegisterNow.webp"
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import flowerimg from "../../assets/loginpage.avif"
import Swal from "sweetalert2";
import { useLocation } from "react-router-dom";

import { useDispatch } from "react-redux";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';




function Copyright(props) {
    return (
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
        {'Copyright © '}
        <Link color="inherit" href="https://wedmegood.in/">
          Wedmegood.in
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }

  const defaultTheme = createTheme();

export default function Login(){

   var dispatch=useDispatch()
    const [name, setName] = useState('');
    const [password, setPassword] = useState('');
    const[ isPasswordVisible,setIsPasswordVisible]=useState(false)
    const [contact, setContact] = useState('');
    const [open, setOpen] = useState(false);
    const [isPinkHovered, setIsPinkHovered] = useState(false);
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    const { setCurrentUser } = useContext(AuthContext);
    var navigate=useNavigate()
    const location = useLocation();
    const from = location.state?.from;

    const theme = useTheme();
    const matchesMd=useMediaQuery(theme.breakpoints.down("md"));

    
  

  
    const handleClickOpen = (index) => {
       
        
        setOpen(true);
      };

      const handleClickSignUp = () => {
        navigate("/registration", { state: { open: true } });
    };
    

    // const handleClick = () => {
    //     const storedUserDetails = JSON.parse(localStorage.getItem("userDetails"));

    //     if (storedUserDetails && email === storedUserDetails.email && password === storedUserDetails.password ||storedUserDetails && phone === storedUserDetails.phone) {
    //         setCurrentUser({ email: storedUserDetails.email,});
    //         localStorage.setItem("isLoggedIn", true);
    //         navigate(from && from.id ? `/cart/${from.id}` : "/profile");
    //     } else {
    //         Swal.fire({
    //             position: "top-end",
    //             icon: "error",
    //             title: "Login failed. Email or password does not match.",
    //             showConfirmButton: false,
    //             timer: 1500,
    //             toast: true,
    //         });
    //     }
    // };


    const handleClick = () => {
        const storedUserDetails = JSON.parse(localStorage.getItem("userDetails"));

        if (storedUserDetails &&
            ((contact === storedUserDetails.email && password === storedUserDetails.password) ||
             (contact === storedUserDetails.phone && password === storedUserDetails.password))) {
            setCurrentUser({ email: storedUserDetails.email });
            localStorage.setItem("isLoggedIn", true);
            navigate("/profile"); // You can adjust navigation as needed
        } else {
            Swal.fire({
                position: "top-end",
                icon: "error",
                title: "Login failed. Email/phone or password does not match.",
                showConfirmButton: false,
                timer: 1500,
                toast: true,
            });
        }
    };
    

    const handleGoogleLogin = async () => {
        try {
            const result = await signInWithPopup(auth, provider);
            const user = result.user;

            // Create user data object with name and email
            const userData = {
                email: user.email,
                name: user.displayName || 'Guest',
               
            };

            // Retrieve existing user data and merge
            const existingData = JSON.parse(localStorage.getItem("userDetails")) || {};
            const mergedData = { ...existingData, ...userData };

            // Set the current user context and update local storage
            setCurrentUser(mergedData);
            localStorage.setItem("userDetails", JSON.stringify(mergedData));

            navigate("/profile");
        } catch (error) {
            console.error("Google login error: ", error);
        }
    };

    
  
     
  
    

    
    

   
   
   
   
   
   
   
   return(<div style={{width:'100%',height:'100%',margin:0,padding:0,overflowX:'hidden',fontFamily:'Playfair Display'}}>
  
      
      {matchesMd?<></> :
<Grid item xs={12}>
     <UpperHeader/>
     </Grid>
}
   
        <Grid container spacing={1}>
         
         <Grid item xs={12} md={6}>
         {/* {banner.length? */}
          
          
          <img src={flowerimg} style={{width:matchesMd?'100%':'90%',height:matchesMd?'100%':'75%',display:'flex',marginTop:matchesMd?<></>:10,padding:matchesMd?<></>:10}} />
        {/* //  : */}
        {/* //  <div style={{width:matchesMd?'100%':'90%',height:matchesMd?'100%':'75%',marginTop:matchesMd?<></>:10,padding:matchesMd?<></>:10}}><Skeleton height={350} count={1}   /></div> */}
{/* } */}
         </Grid>

         <Grid item xs={12} md={6}>
            <Grid item xs={12}    style={{display:'flex',justifyContent:'center',alignItems:'center',fontSize:30,padding:matchesMd?<></>:30,fontWeight:'bold',marginTop:matchesMd?5:<></>,marginBottom:matchesMd?15:<></>}}>
             Sign In / Sign Up
           </Grid>

           <Grid item xs={12} style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
           <div style={{ display:'flex',alignItems:'center',background:'#fff',width:matchesMd?'85%':'65%', height:'28%',border:'solid 1px' }}>

            
           
            <img src ={usericon} style={{width:'7%',padding:6,alignItems:'center'}}/>|
            
            <input   value={contact} onChange={(e) => setContact(e.target.value)} style={{ height:50, width:'100%',  background:'transparent', border:'none', outline:'none', color:'#797979',fontSize:12,marginLeft:matchesMd?<></>:10,}} type="text" placeholder="Enter Your Email*" />

           </div>

           </Grid>


           <Grid item xs={12} style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',marginTop:20}}>
           <div style={{ display:'flex',alignItems:'center',background:'#fff',width:matchesMd?'85%':'65%', height:'28%',border:'solid 1px',padding:3 }}>

            
           
            <img src ={passworde} style={{width:'7%',padding:6,alignItems:'center'}}/>|
            
            <input value={password}  onChange={(e) => setPassword(e.target.value)} style={{ height:50, width:'100%',  background:'transparent', border:'none', outline:'none', color:'#797979',fontSize:12,marginLeft:matchesMd?<></>:10,}} type={isPasswordVisible?'text':'password'} placeholder="Enter Password" />
                
           <div
                        onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                        style={{ cursor: "pointer", padding: 10 }}
                    >
                        {isPasswordVisible ? (
                            <VisibilityOff />
                        ) : (
                            <Visibility />
                        )}
                    </div>
           </div>

           </Grid>

           <Grid item xs={12} style={{display:'flex',justifyContent:'center',alignItems:'center',marginTop:15}}>
                    <Button variant="text" onClick={handleClick}>Sign In</Button>/
             
             <Button variant="text" onClick={handleClickSignUp}>Sign Up</Button>
                </Grid>

                


           {matchesMd?
            <Grid item xs={12} style={{display:'flex',marginTop:60,justifyContent:'center',alignItems:'center'}}>

            -------------- OR ---------------
            </Grid>
           
           
           :

           <Grid item xs={12} style={{display:'flex',marginTop:30,justifyContent:'center',alignItems:'center'}}>

           ------------------------- OR --------------------------
           </Grid>

}

           <Grid item xs={12} style={{display:'flex',fontSize:23,justifyContent:'center',fontFamily:'kanit',fontWeight:'bold', marginTop:20}}>
           Continue With
               </Grid>

               {/* <Grid item xs={6} style={{width:'40%',height:'10%', border:'solid 1px',padding:15,marginLeft:matchesMd?'30%':180,display:'flex',justifyContent:'center',marginTop:30}}>
                   <span style={{display:'flex',justifyContent:'center',alignItems:'center'}}>  <img src ={facebook} style={{width:'12%',alignItems:'center'}}/> Facebook  </span>
                  
               </Grid> */}

               <Grid item xs={6} style={{width:matchesMd?'18%':'28%',height:'7%', border:'solid 1px',padding:matchesMd?12:15,marginLeft:matchesMd?'40%':231,display:'flex',justifyContent:'center',alignItems:'center',marginTop:30,borderRadius:20}}>
              
                   <span style={{display:'flex',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={handleGoogleLogin} > <img src ={google} style={{width:matchesMd?'18%':'15%',alignItems:'center'}}/> Google  </span>
            

               </Grid>

             

               
                    

               

               

            
        




</Grid>

         
        


        </Grid>

      
    
    </div>)


}